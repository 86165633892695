import styled from 'styled-components';

export { PageTitle, Title, SubTitle, Body } from './Text';

export const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props: any) => props.style};
`;

export const HFlex = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  ${(props: any) => props.style};
`;
//min-width: 0px;

export const VFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  ${(props: any) => props.style};
`;
//min-height: 0px;
