import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import logo from './logo.svg';
import './App.css';

import { createBrowserHistory } from 'history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import { HomePage, CompanyPage, AdminPage } from 'pages';
import { Colors } from 'resources/Constants';

const history = createBrowserHistory();

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 20,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.5em',
        borderRadius: 10,
      },
    },
  },
  palette: {
    primary: {
      main: Colors.BASE,
      dark: Colors.BASE,
    },
    secondary: {
      main: Colors.BASE,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route path="/admin">
              <AdminPage history={history} />
            </Route>

            <Route path="/companies">
              <CompanyPage history={history} />
            </Route>

            <Route path="/">
              <HomePage history={history} />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
