export enum SourceType {
  original = 'original',
  calculated = 'calculated',
}

export default class Source {
  type?: SourceType;
  // name of the article, dataset, etc.
  title?: string;
  // organization name
  org?: string;
  // link to the resource
  url?: string;
  verificationURL?: string;

  constructor(
    type?: string,
    title?: string,
    org?: string,
    url?: string,
    verificationURL?: string
  ) {
    this.type = type as SourceType;
    this.title = title;
    this.org = org;
    this.url = url;
    this.verificationURL = verificationURL;
  }

  getPureObject() {
    let pureObject: any = Object.assign({}, this);
    Object.keys(pureObject).forEach((key) =>
      pureObject[key] === undefined ||
      pureObject[key] === null ||
      pureObject[key] === '' ||
      pureObject[key] === NaN
        ? delete pureObject[key]
        : {}
    );
    return pureObject;
  }

  /**
   * returns source in the way it's stored in the database
   */
  getStatSource() {
    return {
      sourceType: this.type,
      sourceTitle: this.title,
      sourceOrg: this.org,
      sourceURL: this.url,
      verificationURL: this.verificationURL,
    };
  }
}
