import React from 'react';

import { PageTitle, HFlex, VFlex } from 'components/general';
import { Style, DEVELOPMENT_MODE, BASE_URL } from 'resources/Constants';
import LogoWhite from 'resources/assets/logo-white.png';

import { SearchBar } from 'components/search';

interface Props {
  sticky?: boolean;
  hideSearchBar?: boolean;

  children?: JSX.Element | JSX.Element[];
  style?: {};
}

interface State {}

class NavBar extends React.Component<Props, State> {
  render() {
    const { sticky, style, hideSearchBar, children } = this.props;
    let stickyStyle: {} = sticky
      ? { position: 'absolute', top: 0, left: 0, right: 0 }
      : {};
    return (
      <HFlex
        style={{
          position: 'relative',
          zIndex: 2147483647,
          minHeight: 80,
          alignItems: 'center',
          justifyContent: 'space-between',

          ...Style.pagePaddingHorizontal,
          ...stickyStyle,
          ...style,
        }}
      >
        <img
          src={LogoWhite}
          height={60}
          width={60}
          style={{ cursor: 'pointer' }}
          onClick={() => window.location.replace(BASE_URL)}
          alt="Company Carbon"
        />

        {!hideSearchBar && <SearchBar style={{ maxWidth: 300 }} />}

        {children}
      </HFlex>
    );
  }
}

export default NavBar;
