import React from 'react';
import { History } from 'history';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectSearchBox,
  connectHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import { ButtonBase, InputBase, TextField } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { SubTitle, Body, HFlex, VFlex } from 'components/general';
import {
  Algolia,
  Colors,
  Layout,
  DEVELOPMENT_MODE,
  BASE_URL,
} from 'resources/Constants';

const searchClient = algoliasearch(Algolia.appId, Algolia.searchId);

interface Hit {
  objectID: string;
  name: string;
  description: string;
  logoURL: string;
}

// @ts-ignore
const TextFieldSearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
  return (
    <InputBase
      placeholder="Search for a company"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      inputProps={{ 'aria-label': 'search google maps' }}
      style={{
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 30,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 20,
        boxShadow: Layout.BOX_SHADOW,
      }}
    />
  );
  return (
    <TextField
      id="outlined-basic"
      label="Search for a company"
      variant="standard"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      //inputProps={{ style: { backgroundColor: 'white' } }}
      style={{ backgroundColor: 'white', borderRadius: 20 }}
    />
  );
};
const CustomSearchBox = connectSearchBox(TextFieldSearchBox);

// @ts-ignore
const PreviewHits = ({ hits }) => {
  const MAX_HITS = 4;
  const hitsToRender = hits.length > MAX_HITS ? hits.slice(0, MAX_HITS) : hits;
  return (
    <VFlex
      style={{
        position: 'absolute',
        width: '100%',
        top: 70,
        flex: 1,
        maxHeight: 200,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: Layout.BOX_SHADOW,
      }}
    >
      {hitsToRender.length > 0 ? (
        hitsToRender.map((hit: Hit) => (
          <ButtonBase
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
            }}
            onClick={() =>
              window.location.replace(`${BASE_URL}companies/${hit.objectID}`)
            }
          >
            <HFlex style={{ flex: 1, alignItems: 'center', minWidth: 0 }}>
              <VFlex
                style={{
                  height: 40,
                  width: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                <img
                  alt={hit.name + ' Logo'}
                  src={hit.logoURL}
                  height={'auto'}
                  width={'auto'}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
              </VFlex>
              <VFlex style={{ flex: 1, minWidth: 0 }}>
                <SubTitle
                  style={{
                    textAlign: 'left',
                    minWidth: 0,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {hit.name}
                </SubTitle>
                <Body
                  style={{
                    textAlign: 'left',
                    minWidth: 0,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    color: 'gray',
                    fontStyle: hit.description ? 'normal' : 'italic',
                  }}
                >
                  {hit.description ? hit.description : 'No description'}
                </Body>
              </VFlex>
              <ArrowForwardIosRoundedIcon />
            </HFlex>
          </ButtonBase>
        ))
      ) : (
        <VFlex>
          <Body>No results found</Body>
          <a
            href="https://airtable.com/shroPTF38aEJ0Wd0I"
            target="_blank"
            style={{ color: Colors.BASE }}
          >
            request a company
          </a>
        </VFlex>
      )}
    </VFlex>
  );
};

const CustomHits = connectHits(PreviewHits);

const Results = connectStateResults(({ searchState }) =>
  searchState && searchState.query ? <CustomHits /> : null
);

interface Props {
  //history: History;
  style?: {};
}

export default class SearchBar extends React.Component<Props> {
  render() {
    const { style } = this.props;
    return (
      <VFlex style={{ flex: 1, position: 'relative', ...style }}>
        <InstantSearch
          indexName={Algolia.companiesIndex}
          searchClient={searchClient}
        >
          <CustomSearchBox />
          <Results />
        </InstantSearch>
      </VFlex>
    );
  }
}
