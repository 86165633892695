// @ts-nocheck

import { Company, Stat } from 'model';
import moment from 'moment';

const firebase = require('firebase');
// Required for side-effects
require('firebase/firestore');

let db: any;

enum FirestoreCollections {
  companies = 'companies',
}

export default class FirestoreManager {
  public static initialize() {
    var firebaseConfig = {
      apiKey: 'AIzaSyDhRYFpv9X3eVAoXIuy1nOXLtyX3J0oCvQ',
      authDomain: 'companycarbon.firebaseapp.com',
      databaseURL: 'https://companycarbon.firebaseio.com',
      projectId: 'companycarbon',
      storageBucket: 'companycarbon.appspot.com',
      messagingSenderId: '270488816777',
      appId: '1:270488816777:web:e861a9e45f2db9b7d34a58',
      measurementId: 'G-BF40KH9ETX',
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    db = firebase.firestore();
  }

  public static async getAllData() {
    return await db
      .collection('companies')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(`${doc.id} => ${doc.data()}`);
          console.log(doc.data());
        });
      });
  }

  public static getCompanyData(companyID: string) {
    console.log(companyID);
    return new Promise((resolve, reject) => {
      db.collection(FirestoreCollections.companies)
        .doc(companyID)
        .get()
        .then((doc) => {
          console.log('here getting collection');
          console.log(doc.data());
          if (doc.exists) {
            resolve(new Company(doc.data()));
          } else {
            // TODO: add some kind of an error message
            reject();
          }
        });
    });
  }

  // TODO: add some form of guard on the data uploaded
  public static updateCompanies(csvData: [][]) {
    let columns = csvData[0];
    let rows = csvData.slice(1, csvData.length);
    for (var row of rows) {
      let rowDict: any = {};
      for (var i = 0; i < columns.length; i++) {
        rowDict[columns[i]] = row[i];
      }

      let company = new Company(rowDict);
      company.lastUpdated = new Date();
      let companyPureObject = company.getPureObject();

      db.collection('companies')
        .doc(company.companyId)
        .set(companyPureObject, { merge: true })
        .then(function () {
          console.log('Document successfully written!');
        })
        .catch(function (error) {
          console.error('Error writing document: ', error);
        });
    }
  }

  // TODO: add some form of guard on the data uploaded
  public static updateDataForCompanies(csvData: [][]) {
    let columns = csvData[0];
    let rows = csvData.slice(1, csvData.length);

    let companyStats: { [companyId: string]: any[] } = {};
    for (var row of rows) {
      let rowDict: any = {};
      for (var i = 0; i < columns.length; i++) {
        rowDict[columns[i]] = row[i];
      }

      let companyId = rowDict.companyId;
      let stat = new Stat(rowDict);
      let statPureObject = stat.getPureObject();
      if (companyStats[companyId]) {
        companyStats[companyId].push(statPureObject);
      } else {
        companyStats[companyId] = [statPureObject];
      }
    }

    Object.keys(companyStats).forEach((companyId: string) => {
      db.collection('companies')
        .doc(companyId)
        .set(
          {
            data: companyStats[companyId],
            lastUpdated: new Date(),
          },
          { merge: true }
        )
        .then(function () {
          console.log('Document successfully written!');
        })
        .catch(function (error) {
          console.error('Error writing document: ', error);
        });
    });
  }
}
