import React from 'react';
import {
  ButtonBase,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Chart } from 'react-google-charts';
import { History } from 'history';
import Axios from 'axios';

import {
  Root,
  HFlex,
  VFlex,
  PageTitle,
  Title,
  SubTitle,
  Body,
} from 'components/general';

import { FirestoreManager, UnitConverter } from 'managers';

import { Colors, Layout, Style, BASE_URL } from 'resources/Constants';
import Company, { CompanyType } from 'model/Company';
import { Dataset, Stat, StatType, STAT_TYPE_TRAITS } from 'model';
import { StatUnit } from 'model/Stat';

import { NavBar } from 'components/navigation';

interface Props {
  history: History;
}

interface State {
  company?: Company;

  // Headline Stats to Display
  revenueStat?: Stat;
  emissionsStat?: Stat;
  emissionIntensityStat?: Stat;

  datasets: { [name: string]: Dataset };
  selectedDataset?: string;
  comparisonDataset?: string;
}

export default class CompanyPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      datasets: {},
    };
  }

  componentDidMount() {
    let companyId = window.location.pathname.split('/')[2];
    // ERROR: only do this if there is in fact a path name, otherwise redirect home for now
    // TODO: add a loading screen
    if (!companyId) {
      window.location.replace(BASE_URL);
    }
    FirestoreManager.getCompanyData(companyId).then((data) => {
      let company = data as Company;
      this.setState({ company });
      this.sortCompanyData(company.data);

      // https://www.mediawiki.org/wiki/Extension:TextExtracts#API
      if (company.wikipediaURL) {
        let urlArray = company.wikipediaURL.split('/');
        let wikipediaArticleName = urlArray[urlArray.length - 1];

        Axios.get(
          `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=extracts&titles=${wikipediaArticleName}&exchars=200&explaintext=1&origin=*`
        ).then((res) => {
          if (res.data) {
            try {
              let pages = res.data.query.pages;

              company.description = pages[Object.keys(pages)[0]].extract;
              this.setState({});
            } catch {}
          }
        });
      }

      // TODO: possibly use this to get resources for description of company
      // https://github.com/mozilla/page-metadata-parser
    });
  }

  sortCompanyData(data?: Stat[]) {
    if (!data || data.length === 0) return;
    let revenueStat: Stat | undefined = undefined;
    let emissionsStat: Stat | undefined = undefined;
    let emissionIntensityStat: Stat | undefined = undefined;
    let datasets: { [name: string]: Dataset } = {};
    for (var i = 0; i < data.length; i++) {
      let stat: Stat = data[i];
      switch (stat.type) {
        case StatType.revenue:
          if (!revenueStat) {
            revenueStat = stat;
          } else if (stat.year! > revenueStat.year!) {
            revenueStat = stat;
          }
          break;
        case StatType.emissions:
          if (!emissionsStat) {
            emissionsStat = stat;
          } else if (stat.year! > emissionsStat.year!) {
            emissionsStat = stat;
          }
          break;
        case StatType.emissionIntensity:
          if (!emissionIntensityStat) {
            emissionIntensityStat = stat;
          } else if (stat.year! > emissionIntensityStat.year!) {
            emissionIntensityStat = stat;
          }
          break;
      }

      let datasetName = stat.type + ' by ' + stat.duration;
      if (datasets[datasetName]) {
        datasets[datasetName].data.push(stat);
      } else {
        datasets[datasetName] = { name: datasetName, data: [stat] };
      }
    }

    // TODO: sort more accurately
    Object.keys(datasets).map((d) => {
      datasets[d].data.sort(function (s1, s2) {
        let x: number = s1.year!;
        let y: number = s2.year!;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    });
    // TODO: sort the datasets by date

    let selectedDataset: string | undefined;
    if (Object.keys(datasets).length > 0) {
      if (datasets['revenue by year']) selectedDataset = 'revenue by year';
      if (datasets['emission intensity by year'])
        selectedDataset = 'emission intensity by year';
      if (datasets['emissions by year']) selectedDataset = 'emissions by year';
    }
    this.setState({
      revenueStat,
      emissionsStat,
      emissionIntensityStat,
      datasets,
      selectedDataset,
    });
  }

  _testFakeAmazonData() {
    const amazon = new Company({
      name: 'Amazon',
      logoURL:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2880px-Amazon_logo.svg.png',
      website: 'www.amazon.com',
      companyType: CompanyType.public,
    });
  }

  _renderCompanyHeader() {
    const { company } = this.state;
    if (!company) return null;
    return (
      <HFlex
        style={{
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          flexShrink: 0,
        }}
      >
        {company.logoURL && (
          <Card
            style={{
              borderRadius: 20,
              width: 100,
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: Layout.MARGIN_HORIZONTAL,
              cursor: 'pointer',
              boxShadow: Layout.BOX_SHADOW,
            }}
            onClick={() => {
              if (company.website) {
                window.open('https://' + company.website, '_blank');
              }
            }}
          >
            <img
              alt={company.name + ' Logo'}
              src={company.logoURL}
              height={'auto'}
              width={'auto'}
              style={{
                maxWidth: '90%',
                maxHeight: '90%',
                objectFit: 'contain',
                cursor: company.website ? 'pointer' : 'default',
              }}
            />
          </Card>
        )}
        <VFlex style={{ minWidth: 300, flex: 1 }}>
          <PageTitle style={{ textAlign: 'left' }}>{company.name}</PageTitle>
          {company.description && (
            <Body
              style={{
                textAlign: 'left',
                lineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {company.description + ' '}
              {company.wikipediaURL && (
                <a
                  href={company.wikipediaURL}
                  target="_blank"
                  style={{ color: Colors.BASE }}
                >
                  Wikipedia
                </a>
              )}
            </Body>
          )}
        </VFlex>
      </HFlex>
    );
  }

  renderCoreStat(stat?: Stat) {
    if (!stat) return null;
    let statType = stat.type;

    let bestPrefix = UnitConverter.getBestPrefix(stat.value);
    let cardStyle: any = {};
    let title: string = '';
    let unit: string | undefined = undefined;
    if (stat.type === StatType.emissions) {
      cardStyle = { backgroundColor: 'lightgray' };
      title = bestPrefix.number + bestPrefix.prefix.numPrefixAbbrev;
      if (stat.unit === StatUnit.metricTonsOfCO2e) {
        unit = 'MTCO2e';
      }
    } else if (stat.type === StatType.revenue) {
      cardStyle = { backgroundColor: 'lightgreen' };
      title = '$' + bestPrefix.number + bestPrefix.prefix.numPrefixAbbrev;
    }

    return (
      <Card
        style={{
          borderRadius: 20,
          boxShadow: Layout.BOX_SHADOW,
          backgroundColor: stat.type
            ? STAT_TYPE_TRAITS[stat.type].color
            : 'white',
        }}
      >
        <CardContent
          style={{
            width: 120,
            height: 120,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...cardStyle,
          }}
        >
          <Body>{statType}</Body>
          <VFlex
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            {title && <Title>{title}</Title>}
            {unit && <SubTitle>{unit}</SubTitle>}
          </VFlex>
        </CardContent>
      </Card>
    );
  }

  _renderStat(stat?: Stat) {
    if (!stat) return null;

    let statType = stat.type;

    let bestPrefix = UnitConverter.getBestPrefix(stat.value);
    let cardStyle: any = {};
    let title: string = '';
    let value: string = '';
    let unit: string | undefined = undefined;
    let datasetName: string | undefined;

    if (stat.type === StatType.emissions) {
      cardStyle = { backgroundColor: 'lightgray' };
      title = `Emissions in ${stat.year}`;
      value = bestPrefix.number + bestPrefix.prefix.numPrefixAbbrev;
      if (stat.unit === StatUnit.metricTonsOfCO2e) {
        unit = 'MTCO2e';
      }
      datasetName = 'emissions by year';
    } else if (stat.type === StatType.revenue) {
      cardStyle = { backgroundColor: 'lightgreen' };
      title = `Revenue in ${stat.year}`;
      value = '$' + bestPrefix.number + bestPrefix.prefix.numPrefixAbbrev;
      datasetName = 'revenue by year';
    } else if (stat.type === StatType.emissionIntensity) {
      cardStyle = { backgroundColor: 'lightgreen' };
      title = `Emission Intensity in ${stat.year}`;
      value = bestPrefix.number + bestPrefix.prefix.numPrefixAbbrev;
      console.log('\n\n\nthis is em intensity');
      console.log(value.length);
      if (stat.unit === StatUnit.gCO2ePerDollar) {
        unit = 'g CO2e / dollar';
      }
      datasetName = 'emission intensity by year';
    }

    return (
      <Card
        style={{
          boxShadow: Layout.BOX_SHADOW,
          height: 80,
          minWidth: 300,
          display: 'flex',
          flex: 1,
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: stat.type
            ? STAT_TYPE_TRAITS[stat.type].color
            : 'white',
        }}
      >
        <ButtonBase
          style={{ flex: 1 }}
          onClick={() => this.setState({ selectedDataset: datasetName })}
        >
          <CardContent
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <VFlex
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Body
                style={{
                  textAlign: 'left',
                  fontWeight: 'normal',
                  fontSize: 15,
                }}
              >
                {title}
              </Body>
              <HFlex>
                <Title>{value}</Title>
                {unit && (
                  <Title
                    style={{
                      marginLeft: 10,
                      textAlign: 'left',
                      fontWeight: 'normal',
                    }}
                  >
                    {unit}
                  </Title>
                )}
              </HFlex>
            </VFlex>
          </CardContent>
        </ButtonBase>
      </Card>
    );
  }

  renderCoreStats() {
    const { revenueStat, emissionsStat, emissionIntensityStat } = this.state;
    console.log(emissionIntensityStat);
    return (
      <HFlex
        style={{
          justifyContent: 'flex-start',
          paddingTop: 10,
          paddingBottom: 30,
          flexWrap: 'wrap',
          flexShrink: 0,
        }}
      >
        {this._renderStat(emissionsStat)}
        {this._renderStat(emissionIntensityStat)}
      </HFlex>
    );
  }

  renderChart(d1?: Dataset, d2?: Dataset) {
    if (!d1 || !d1.data || d1.data.length === 0) return;
    let data1: Stat[] = d1.data;
    let xLabel = data1[0].duration!;
    let y1Label = data1[0].type + ` (${data1[0].unit})`;

    interface XYPoint {
      x: number;
      y1?: number;
      y2?: number;
    }

    let datapointsDict: { [x: string]: XYPoint } = {};
    data1.forEach((s: Stat) => {
      if (datapointsDict[s.year!]) {
        datapointsDict[s.year!].y1 = s.value;
      } else {
        datapointsDict[s.year!] = { x: s.year!, y1: s.value };
      }
    });
    let xyLabels = [xLabel, y1Label];
    let series: any = {
      0: { axis: y1Label },
    };
    let axes: any = {
      y: {
        [y1Label]: { label: y1Label },
      },
    };
    let colors: string[] = [STAT_TYPE_TRAITS[data1[0].type!].color!];

    let data2: Stat[] | undefined;
    let y2Label: string | undefined;
    if (d2) {
      data2 = d2.data;
      y2Label = data2[0].type + ` (${data2[0].unit})`;
      xyLabels.push(y2Label!);
      data2.forEach((s: Stat) => {
        if (datapointsDict[s.year!]) {
          datapointsDict[s.year!].y2 = s.value;
        } else {
          datapointsDict[s.year!] = { x: s.year!, y2: s.value };
        }
      });

      series[1] = { axis: y2Label };
      axes.y[y2Label] = { label: y2Label };
      colors.push(STAT_TYPE_TRAITS[data2[0].type!].color!);
    }

    let xyDatapoints = Object.keys(datapointsDict).map((x: string) => {
      let p = datapointsDict[x];
      if (data2) {
        return [`${p.x}`, p.y1, p.y2];
      } else {
        return [`${p.x}`, p.y1];
      }
    });

    return (
      <Chart
        width={'100%'}
        height={'100%'}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={[xyLabels, ...xyDatapoints]}
        options={{
          // Material design options
          // Gives each series an axis name that matches the Y-axis below.
          series,
          // Adds labels to each axis; they don't have to match the axis names.
          axes,
          colors,
          legend: { position: 'none' },
        }}
      />
    );
  }

  renderPrimaryChart() {
    const { datasets } = this.state;
    if (!datasets) return;
    let emissionsByYear = datasets[StatType.emissions + ' by year'];
    let revenueByYear = datasets[StatType.revenue + ' by year'];
    interface ERYDatapoint {
      year: number;
      emissions?: number;
      revenue?: number;
    }

    let eryDictionary: { [year: number]: ERYDatapoint } = {};
    if (emissionsByYear) {
      emissionsByYear.data.map((s) => {
        if (eryDictionary[s.year!]) {
          eryDictionary[s.year!].emissions = s.value;
        } else {
          eryDictionary[s.year!] = { year: s.year!, emissions: s.value };
        }
      });
    }

    if (revenueByYear) {
      revenueByYear.data.map((s) => {
        if (eryDictionary[s.year!]) {
          eryDictionary[s.year!].revenue = s.value;
        } else {
          eryDictionary[s.year!] = { year: s.year!, revenue: s.value };
        }
      });
    }

    let datapoints = Object.keys(eryDictionary).map((d) => {
      let dp: ERYDatapoint = eryDictionary[parseInt(d)];
      return [`${dp.year}`, dp.emissions, dp.revenue];
    });
    return (
      <Card>
        <CardContent>
          <Chart
            chartType="Bar"
            width={'100%'}
            height={600}
            loader={<div>Loading Chart</div>}
            data={[
              ['Year', 'Emissions (MTCO2e)', 'Revenue ($)'],
              ...datapoints,
            ]}
            options={{
              // Material design options
              chart: {
                title: 'Emissions and Revenue by Year',
                subtitle: '',
              },
              colors: ['lightgray', 'lightgreen'],
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: 'Emissions' },
                1: { axis: 'Revenue' },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Emissions: { label: 'Emissions (MTCO2e)' },
                  Revenue: { label: 'Revenue ($)' },
                },
              },
              legend: { position: 'none' },
            }}
          />
        </CardContent>
      </Card>
    );
  }

  _renderDatasetSelector(
    title: string,
    dataset: string,
    onChange: (dataset?: string) => void
  ) {
    const { datasets } = this.state;
    return (
      <FormControl
        variant="outlined"
        style={{ flex: 1, minWidth: 300, marginBottom: 10 }}
      >
        <InputLabel id="selected-chart-label" style={{ fontSize: 20 }}>
          {title}
        </InputLabel>
        <Select
          labelId="selected-chart-label"
          value={dataset}
          onChange={(event: any) => {
            onChange(event.target.value);
          }}
          label={title}
          style={{ fontSize: 20 }}
        >
          {Object.keys(datasets).map((name: string) => (
            <MenuItem value={name} style={{ fontSize: 20 }}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  _renderChartCard() {
    const { datasets, selectedDataset, comparisonDataset } = this.state;
    return (
      <Card
        style={{
          width: '100%',
          height: window.innerHeight * 0.8,
          boxShadow: Layout.BOX_SHADOW,
          display: 'flex',
          flexShrink: 0,
        }}
      >
        <CardContent style={{ display: 'flex', flex: 1, width: '100%' }}>
          <VFlex style={{ flex: 1, padding: 20 }}>
            <HFlex style={{ width: '100%', flexWrap: 'wrap' }}>
              {selectedDataset &&
                this._renderDatasetSelector(
                  'Dataset',
                  selectedDataset,
                  (dataset?: string) => {
                    this.setState({ selectedDataset: dataset });
                  }
                )}
              {comparisonDataset ? (
                this._renderDatasetSelector(
                  'Comparison Dataset',
                  comparisonDataset,
                  (dataset?: string) => {
                    this.setState({ comparisonDataset: dataset });
                  }
                )
              ) : (
                <Tooltip title="Add Dataset">
                  <ButtonBase
                    style={{ width: 60, height: 60, borderRadius: '50%' }}
                    onClick={() => {
                      if (Object.keys(datasets).length > 0) {
                        this.setState({
                          comparisonDataset: Object.keys(datasets)[0],
                        });
                      }
                    }}
                  >
                    <VFlex
                      style={{ justifyContent: 'center', alignItems: 'center' }}
                    >
                      <AddCircleRoundedIcon
                        style={{ fontSize: 30, color: Colors.BASE }}
                      />
                    </VFlex>
                  </ButtonBase>
                </Tooltip>
              )}
            </HFlex>
            <VFlex
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              {Object.keys(datasets).length === 0 ? (
                <Body>Not enough data available.</Body>
              ) : selectedDataset ? (
                this.renderChart(
                  datasets[selectedDataset],
                  comparisonDataset ? datasets[comparisonDataset] : undefined
                )
              ) : (
                this.renderChart()
              )}
            </VFlex>
          </VFlex>
        </CardContent>
      </Card>
    );
  }

  render() {
    const { company } = this.state;
    if (!company) return null;

    return (
      <Root>
        <NavBar sticky={false} style={{ backgroundColor: Colors.BASE }} />
        <VFlex
          style={{
            width: '100%',
            maxWidth: 1000,
            alignSelf: 'center',
            paddingTop: 20,
            paddingBottom: 100,
            minHeight: 'auto',
            flexShrink: 0,
            ...Style.pagePaddingHorizontal,
          }}
        >
          {this._renderCompanyHeader()}
          <Divider
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: '80%',
              alignSelf: 'center',
              backgroundColor: Colors.BASE,
            }}
          />
          {this.renderCoreStats()}

          {this._renderChartCard()}
          {/*this.renderPrimaryChart()*/}
        </VFlex>
      </Root>
    );
  }
}
