import Stat from './Stat';
export enum CompanyType {
  public = 'public',
  private = 'private',
}

export default class Company {
  // core
  name: string;
  companyId: string;
  description?: string;
  logoURL?: string;
  website?: string;

  // fringe
  stockTicker?: string;
  companyType?: CompanyType;
  wikipediaURL?: string;

  // edit settings
  lastUpdated?: string;

  data?: Stat[];

  constructor(o: any) {
    this.name = o.name as string;
    this.companyId = o.companyId as string;
    this.description = o.description as string;
    this.logoURL = o.logoURL as string;
    this.website = o.website as string;

    this.stockTicker = o.stockTicker as string;
    this.companyType = o.companyType;
    this.wikipediaURL = o.wikipediaURL;
    if (o.lastUpdated) {
      this.lastUpdated = o.lastUpdated;
    }

    if (o.data) {
      this.data = o.data.map((d: any) => new Stat(d));
    }
  }

  getPureObject() {
    let pureObject: any = Object.assign({}, this);
    Object.keys(pureObject).forEach((key) =>
      pureObject[key] === undefined ||
      pureObject[key] === null ||
      pureObject[key] === '' ||
      pureObject[key] === NaN
        ? delete pureObject[key]
        : {}
    );
    return pureObject;
  }
}
