export interface BestPrefix {
  number: number;
  prefix: NumberPrefix;
}

export interface NumberPrefix {
  magnitude: number;
  siPrefix: string;
  siPrefixAbbrev: string;
  numPrefix: string;
  numPrefixAbbrev: string;
}

export const PREFIXES: { [mag: number]: NumberPrefix } = {
  1: {
    magnitude: 1,
    siPrefix: '',
    siPrefixAbbrev: '',
    numPrefix: '',
    numPrefixAbbrev: '',
  },
  1000: {
    magnitude: 1000,
    siPrefix: 'kilo',
    siPrefixAbbrev: 'k',
    numPrefix: 'thousand',
    numPrefixAbbrev: 'k',
  },
  1000000: {
    magnitude: 1000000,
    siPrefix: 'mega',
    siPrefixAbbrev: 'M',
    numPrefix: 'million',
    numPrefixAbbrev: 'M',
  },
  1000000000: {
    magnitude: 1000000000,
    siPrefix: 'giga',
    siPrefixAbbrev: 'G',
    numPrefix: 'billion',
    numPrefixAbbrev: 'B',
  },
  1000000000000: {
    magnitude: 1000000000000,
    siPrefix: 'tera',
    siPrefixAbbrev: 'T',
    numPrefix: 'trillion',
    numPrefixAbbrev: 'T',
  },
};

class UnitConverter {
  getBestPrefix(value: number): BestPrefix {
    // TODO: implement digits and another thing? like maybe best? look at convert-units library
    let best: BestPrefix | undefined = undefined;
    let max = 1000;
    for (var mag of Object.keys(PREFIXES)) {
      // @ts-ignore
      let quotient = Math.floor(value / PREFIXES[mag].magnitude);
      if (quotient < max && quotient > 0) {
        // @ts-ignore
        best = { number: quotient, prefix: PREFIXES[mag] };
      }
    }

    return best!;
  }
}

export default new UnitConverter();
