// @ts-nocheck
import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Chart } from 'react-google-charts';
import { History } from 'history';
import { DropzoneDialog } from 'material-ui-dropzone';
import GoogleButton from 'react-google-button';
// @ts-ignore
import { CsvToHtmlTable } from 'react-csv-to-table';
import Papa from 'papaparse';

import {
  Root,
  HFlex,
  VFlex,
  PageTitle,
  Title,
  SubTitle,
  Body,
} from 'components/general';

import { FirestoreManager, UnitConverter } from 'managers';

import { Colors, Layout } from 'resources/Constants';
import Company, { CompanyType } from 'model/Company';
import { Dataset, Stat, StatType } from 'model';
import { StatUnit } from 'model/Stat';

import { NavBar } from 'components/navigation';

const firebase = require('firebase');
var provider = new firebase.auth.GoogleAuthProvider();

const csv = require('csvtojson');

interface User {
  displayName: string;
  email: string;
}

enum DataType {
  company = 'company',
  data = 'data',
}

interface Props {
  history: History;
}

interface State {
  user?: User;

  showUploadModal: boolean;
  dataType?: DataType;
  csvFile?: any;
  csvData?: [][];
}

export default class AdminPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showUploadModal: false,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        // User is signed in.
        this.setState({ user: user as User });
      } else {
        // No user is signed in.
      }
    });
  }

  // @ts-ignore
  handleFileUpload(files) {
    this.setState({
      showUploadModal: false,
    });
    const csvFile = files[0];
    // type, name,
    this.setState({ csvFile });
    console.log(csvFile);

    Papa.parse(csvFile, {
      complete: (results: any) => {
        if (results && results.data) {
          const csvData = results.data;
          this.setState({ csvData });
          /*
          console.log(csvData);
          console.log(typeof csvData);
          console.log(csvData[0]);
          console.log('numrows is ' + (csvData.length - 1));*/
        }
      },
    });
  }

  _renderLoginScreen() {
    return (
      <VFlex
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <GoogleButton
          onClick={() => {
            firebase
              .auth()
              .signInWithPopup(provider)
              .then((result: any) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                // displayName, email, photoURL
                console.log(user);
                this.setState({ user: user as User });
              })
              .catch((error: any) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
          }}
        />
      </VFlex>
    );
  }

  _renderUploadScreen() {
    const { csvFile, csvData } = this.state;
    return (
      <VFlex style={{ minWidth: 0 }}>
        {!csvFile ? (
          <HFlex style={{ justifyContent: 'space-around' }}>
            <Button
              style={{
                backgroundColor: Colors.BASE,
                color: 'white',
                width: 250,
              }}
              size="large"
              onClick={() => {
                this.setState({
                  showUploadModal: true,
                  dataType: DataType.company,
                });
              }}
            >
              Upload Companies CSV
            </Button>
            <Button
              style={{
                backgroundColor: Colors.BASE,
                color: 'white',
                width: 250,
              }}
              size="large"
              onClick={() => {
                this.setState({
                  showUploadModal: true,
                  dataType: DataType.data,
                });
              }}
            >
              Upload Data CSV
            </Button>
          </HFlex>
        ) : (
          <Button
            style={{
              backgroundColor: Colors.BASE,
              color: 'white',
              width: 250,
              alignSelf: 'center',
            }}
            size="large"
            onClick={() => {
              const { dataType } = this.state;
              if (dataType === DataType.company) {
                FirestoreManager.updateCompanies(csvData);
              } else {
                FirestoreManager.updateDataForCompanies(csvData);
              }
            }}
          >
            Upload to Database
          </Button>
        )}
        {csvData && (
          <TableContainer>
            <Table
              style={{
                minWidth: 0,
                overflow: 'auto',
                width: '100%',
                maxWidth: '100%',
              }}
            >
              <TableHead>
                <TableRow>
                  {csvData[0].map((c: string) => (
                    <TableCell>{c}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((row: [], index: number) => {
                  if (index === 0) return null;
                  return (
                    <TableRow>
                      {row.map((value: string) => (
                        <TableCell>{value}</TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </VFlex>
    );
  }

  render() {
    const { user, showUploadModal } = this.state;
    return (
      <Root>
        <NavBar sticky={false} style={{ backgroundColor: Colors.BASE }} />
        <PageTitle style={{ marginTop: 20 }}>Admin Portal</PageTitle>
        <VFlex
          style={{
            width: '75%',
            maxWidth: 1000,
            alignSelf: 'center',
            flex: 1,
            paddingTop: 20,
            paddingBottom: 100,
          }}
        >
          {user ? this._renderUploadScreen() : this._renderLoginScreen()}
        </VFlex>

        <DropzoneDialog
          open={showUploadModal}
          filesLimit={1}
          showFileNames
          onSave={this.handleFileUpload.bind(this)}
          acceptedFiles={['.csv', 'text/csv', 'application/csv']}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={() => this.setState({ showUploadModal: false })}
        />
      </Root>
    );
  }
}
