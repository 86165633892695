export const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development';

export const BASE_URL = DEVELOPMENT_MODE
  ? 'http://localhost:3000/'
  : 'http://companycarbon.com/';

export const Colors = {
  BASE: 'rgb(51,131,246)',
  GREEN: 'rgb(104,209,114)',
};

export const Layout = {
  PAGE_MARGIN_HORIZONTAL: 20,
  MARGIN_HORIZONTAL: 20,
  BOX_SHADOW: '0px 2px 3px 2px rgba(60,64,67,.30)',
};

export const Style = {
  pagePaddingHorizontal: {
    paddingLeft: Layout.PAGE_MARGIN_HORIZONTAL,
    paddingRight: Layout.PAGE_MARGIN_HORIZONTAL,
  },
  pageMarginHorizontal: {
    marginLeft: Layout.PAGE_MARGIN_HORIZONTAL,
    marginRight: Layout.PAGE_MARGIN_HORIZONTAL,
  },
};

export const Algolia = {
  appId: 'A7ADIME8VX',
  searchId: 'f407af4ed89f514a311960b7d8f5503a',
  companiesIndex: 'prod_CompanyCarbon',
};
