import Source from './Source';

export enum StatDuration {
  year = 'year',
  quarter = 'quarter',
  month = 'month',
  cummulative = 'cummulative',
}

export enum StatType {
  // monetary
  revenue = 'revenue',

  // emissions
  emissions = 'emissions',

  // emission intensity
  emissionIntensity = 'emission intensity',
}

export interface StatTypeTraits {
  color?: string;
}

export const STAT_TYPE_TRAITS: { [t: string]: StatTypeTraits } = {
  [StatType.revenue]: { color: 'rgb(104,209,114)' },
  [StatType.emissions]: { color: 'rgb(158,176,199)' },
  [StatType.emissionIntensity]: { color: 'rgb(123,185,183)' },
};

export enum StatUnit {
  // monetary
  usDollar = '$',

  // emissions
  metricTonsOfCO2e = 'metric tons of CO2e',

  // emission intensity
  gCO2ePerDollar = 'g CO2e per dollar',
}

export default class Stat {
  // core
  value: number;
  unit: StatUnit;
  type?: StatType;

  // time
  duration?: StatDuration;
  year?: number;
  month?: number;
  quarter?: number;

  // edit history
  lastUpdated?: string;

  // source
  source?: Source;

  constructor(o: any) {
    this.value = parseFloat(o.value);
    this.unit = o.unit as StatUnit;
    this.type = o.type as StatType;

    this.duration = o.duration as StatDuration;
    this.year = parseFloat(o.year) ? parseFloat(o.year) : undefined;
    this.month = parseFloat(o.month) ? parseFloat(o.month) : undefined;
    this.quarter = parseFloat(o.quarter) ? parseFloat(o.quarter) : undefined;

    this.lastUpdated = o.lastUpdated;

    this.source = new Source(
      o.sourceType,
      o.sourceTitle,
      o.sourceOrg,
      o.sourceURL,
      o.verificationURL
    );
  }

  getPureObject() {
    const data: any = {
      ...this,
      ...(this.source && this.source.getStatSource()),
    };
    if (this.source) delete data.source;

    let pureObject: any = Object.assign({}, data);
    Object.keys(pureObject).forEach((key) =>
      pureObject[key] === undefined ||
      pureObject[key] === null ||
      pureObject[key] === '' ||
      pureObject[key] === NaN
        ? delete pureObject[key]
        : {}
    );
    return pureObject;
  }
}
