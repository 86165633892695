import styled from 'styled-components';

export const Text = styled.p`
  font-family: 'Helvetica Neue';
  color: black;
  margin: 0;
`;

export const PageTitle = styled(Text)`
  font-size: 2.5em;
  font-weight: bolder;
  ${(props: any) => props.style};
`;

export const Title = styled(Text)`
  font-size: 2em;
  font-weight: bolder;
  ${(props: any) => props.style};
`;

export const SubTitle = styled(Text)`
  font-size: 1.5em;
  font-weight: bolder;
  ${(props: any) => props.style};
`;

export const Body = styled(Text)`
  font-size: 1em;
  font-weight: normal;
  ${(props: any) => props.style};
`;
