import React from 'react';
import { Card } from '@material-ui/core';
// @ts-ignore
import ReactPageScroller from 'react-page-scroller';
import { History } from 'history';

import { Root } from 'components/general';

import { Colors, Layout, Style } from 'resources/Constants';
import Company, { CompanyType } from 'model/Company';

import { SearchBar } from 'components/search';
import { NavBar } from 'components/navigation';
import { PageTitle, Title, SubTitle, HFlex, VFlex } from 'components/general';

import PowerPlantImage from './powerplant.jpg';

interface Props {
  history: History;
}

interface State {}

export default class HomePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Root style={{ backgroundColor: Colors.BASE }}>
        <NavBar sticky hideSearchBar>
          <Title style={{ fontFamily: 'Aquire', color: 'white' }}>
            Company Carbon
          </Title>
          {/* used to counterbalance the logo */}
          <HFlex style={{ width: 60, height: 60 }} />
        </NavBar>

        <VFlex
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${PowerPlantImage})`,
            backgroundRepeat: 'norepeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            ...Style.pagePaddingHorizontal,
          }}
        >
          <Title style={{ color: 'white' }}>visualize data on</Title>
          <PageTitle style={{ color: 'white', fontSize: '4em' }}>
            corporate emissions
          </PageTitle>
          <div
            style={{
              width: '100%',
              maxWidth: 500,
              marginTop: 20,
              zIndex: 10000,
            }}
          >
            <SearchBar />
          </div>
          {/*<video
              autoPlay
              muted
              loop
              src={FactoryVideo}
              style={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                objectFit: 'cover',
                position: 'absolute',
              }}
            />*/}
        </VFlex>

        {/*<ReactPageScroller>
          
          <div
            style={{
              width: window.innerWidth,
              height: window.innerHeight,
              position: 'relative',
            }}
          >
            <video
              autoPlay
              muted
              loop
              src={ForestVideo}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        </ReactPageScroller>*/}
      </Root>
    );
  }
}
